import React from "react";

const AppCardPagination = (props: any) => {
  const pagination = props.pagination;

  let pages = Array.from(Array(pagination.totalPages).keys());

  const leftOffset = pagination.page < 4 ? 0 : pagination.page - 4;
  const rightOffset =
    pagination.page > pages.length - 3 ? pages.length + 1 : pagination.page + 3;

  const slicedPages = pages.slice(leftOffset, rightOffset);

  if (pages.length < 3) {
    return (
      <div className="text-center">
        <nav>
          <ul className="pagination justify-content-center">
            {slicedPages.map(page => {
              return (
                <li
                  className={
                    "page-item" +
                    (page + 1 === pagination.page ? " active" : "")
                  }
                >
                  <a
                    onClick={() => props.goToPage(page + 1)}
                    className="page-link"
                    href="javascript:void(0)"
                  >
                    {page + 1}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <div className="text-center">
      <nav>
        <ul className="pagination justify-content-center">
          {
            <li
              className={
                "page-item" + (pagination.page === 1 ? " disabled" : "")
              }
            >
              <a
                onClick={() => props.goToPage(pagination.page[0])}
                className="page-link"
                href="javascript:void(0)"
              >
                First
              </a>
            </li>
          }
          {
            <li
              className={
                "page-item" + (pagination.page === 1 ? " disabled" : "")
              }
            >
              <a
                onClick={() => props.goToPage(pagination.page - 1)}
                className="page-link"
                href="javascript:void(0)"
              >
                Previous
              </a>
            </li>
          }
          {slicedPages.map((page, index) => {
            return (
              <li
                key={index}
                className={
                  "page-item" + (page + 1 === pagination.page ? " active" : "")
                }
              >
                <a
                  onClick={() => props.goToPage(page + 1)}
                  className="page-link"
                  href="javascript:void(0)"
                >
                  {page + 1}
                </a>
              </li>
            );
          })}
          {
            <li
              className={
                "page-item" +
                (pagination.page >= pagination.totalPages ? " disabled" : "")
              }
            >
              <a
                onClick={() => props.goToPage(pagination.page + 1)}
                className="page-link"
                href="javascript:void(0)"
              >
                Next
              </a>
            </li>
          }
          {
            <li
              className={
                "page-item" +
                (pagination.page >= pagination.totalPages ? " disabled" : "")
              }
            >
              <a
                onClick={() => props.goToPage(pagination.totalPages)}
                className="page-link"
                href="javascript:void(0)"
              >
                Last
              </a>
            </li>
          }
        </ul>
      </nav>
    </div>
  );
};

export default AppCardPagination;
