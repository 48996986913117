import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import AppDatePicker from 'elements/datepicker/AppDatePicker';
import NewCardModel from 'modules/new-card/models/NewCardModel';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";


const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const cardReferenceRegex = RegExp(
  /^(.+)*$/
);
const birthDateRegex = RegExp(
  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))*$/
);

export class CardForm extends React.Component<any, any> {

  constructor(props: any) {
    super(props);

    
    this.state = {
      isEdit: props.card && props.card.id,
      id: props.card ? props.card.id : null,
      cardReference: props.card ? props.card.referenceNumber : '',
      email: props.card ? props.card.user.email : '',
      firstName: props.card ? props.card.user.firstName : '',
      lastName: props.card ? props.card.user.lastName : '',
      address: props.card ? props.card.user.address : '',
      phone: props.card ? props.card.user.phone : '',
      city: props.card ? props.card.user.city : '',
      postCode: props.card ? props.card.user.postCode : '',
      birthDate: props.card ? props.card.user.birthDate : '',
      note: props.card ? props.card.user.note : '',
      childrenBirthdate: '',
      childrenBirthDates: props.card && CardForm.isJSON(props.card.user.childrenBirthDates) ? JSON.parse(props.card.user.childrenBirthDates) : [],
      childrenBirth: '',
      childrenName: '',
      formErrors: {
        cardReference: '',
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        postCode: '',
        birthDate: '',
        childrenBirthdate: ''
      }
    }
  }
  
    componentDidMount() {
      if(this.props.location.state) {
        this.setState({cardReference: this.props.location.state})
      }
    }
    


  static isJSON(str: string) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }
  
  async onSubmit(e: any) {
    e.preventDefault();
    if (this.state.cardReference !== '') {
      try {
        let response: any = await new NewCardModel(this.props).saveCard(
          this.state.id,
          this.state.cardReference,
          this.state.email,
          this.state.firstName,
          this.state.lastName,
          this.state.address,
          this.state.phone,
          this.state.city,
          this.state.postCode,
          this.state.birthDate,
          this.state.note,
          JSON.stringify(this.state.childrenBirthDates)
        )
        if (response.success) {
          this.props.history.push('/card/' + response.data.referenceNumber);
        } else {
          alert(response.message);
        }
      } catch(e) {
        alert(e.message);
      }
    } else {
      alert('Reference number is required!')
    }
  }

  handleChange = (e: any) => {

    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };
    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characaters required" : "";
        break;
      case "cardReference":
        formErrors.cardReference = cardReferenceRegex.test(value)
          ? ""
          : "invalid card number";
        break;
      case "birthDate":
        formErrors.birthDate = birthDateRegex.test(value)
          ? ""
          : "invalid date, please use the following date format(YYYY-MM-DD)";
        break;
      case "childrenBirthdate":
        formErrors.childrenBirthdate = birthDateRegex.test(value)
          ? ""
          : "invalid date, please use the following date format(YYYY-MM-DD)";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  addItem(e: { preventDefault: () => void; }) {
    if (this.state.childrenBirth === '') return;
    e.preventDefault();
    const newChildrenName = this.state.childrenName;
    const newChildrenBirth = this.state.childrenBirth;
    const child = { 'name': newChildrenName, 'birthDate': newChildrenBirth };
    this.setState({
      childrenBirthDates: [...this.state.childrenBirthDates, child],
      childrenName: '',
      childrenBirth: ''
    });
  }

  removeItem = (deletedName: string) => {
    this.setState({
      childrenBirthDates: this.state.childrenBirthDates.filter((item: { name: string; }) => item.name !== deletedName)
    });
  }
  render() {
    return (
      <div className="">
        <div className="contsent container" style={{ maxWidth: 1140 }}>
          <Form onSubmit={this.onSubmit.bind(this)}>
            <div className="box">
              <div className="header">
                <p>Basic card information</p>
              </div>
              <div className="content">

                <div className="row">
                  <div className="col-lg-6">
                    <FormGroup>
                      <Label for="cardReference">Card number</Label>
                      <Input
                        disabled={this.state.isEdit}
                        id="cardReference"
                        name="cardReference"
                        type="text"
                        style={this.state.cardReference !== '' ? this.state.formErrors.cardReference.length > 0 ? { border: '1px solid red' } : { border: '1px solid gray' } : { border: '1px solid gray' }}
                        onChange={this.handleChange}
                        value={this.state.cardReference} />
                      {this.state.cardReference !== '' ?
                        this.state.formErrors.cardReference.length > 0 && (
                          <span className="error-message">{this.state.formErrors.cardReference}</span>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Label for="firstName">First Name</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        type="text"
                        style={{ border: '1px solid darkgray' }}
                        onChange={this.handleChange}
                        value={this.state.firstName} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="phone">Phone</Label>
                      <Input id="phone" name="phone" type="text" style={{ border: '1px solid darkgray' }} onChange={this.handleChange} value={this.state.phone} />
                    </FormGroup>
                  </div>
                  <div className="col-lg-6">
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        style={this.state.email !== '' ? this.state.formErrors.email.length > 0 ? { border: '1px solid red' } : { border: '1px solid gray' } : { border: '1px solid gray' }}
                        onChange={this.handleChange}
                        value={this.state.email} />
                      {this.state.email !== '' ?
                        this.state.formErrors.email.length > 0 && (
                          <span className="error-message">{this.state.formErrors.email}</span>
                        ) : null}
                    </FormGroup>
                    {/* <FormGroup>
                      <Label for="birthDate">Birthdate</Label>
                      <AppDatePicker id="birthDate" name="birthDate" onChange={(date: string) => this.setState({ birthDate: moment(date).format('YYYY-MM-DD') })} value={this.state.birthDate === '' ? '' : this.state.birthDate} />
                      {this.state.birthDate !== '' ?
                        this.state.formErrors.birthDate.length > 0 && (
                          <span className="error-message">{this.state.formErrors.birthDate}</span>
                          ) : null}
                    </FormGroup> */}
                    <FormGroup>
                      <Label for="lastName">Last Name</Label>
                      <Input id="lasttName" name="lastName" type="text" style={{ border: '1px solid darkgray' }} onChange={this.handleChange} value={this.state.lastName} />
                    </FormGroup>

                    <FormGroup>
                      <Label for="note">Note</Label>
                      <Input id="note" name="note" type="textarea" style={{ border: '1px solid darkgray' }} onChange={this.handleChange} value={this.state.note} rows={10} />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>

            <div className="box">
              <div className="header">
                <p>Birthdays</p>
              </div>
              <div className="content">
                <div className="row">
                  <div className="col-lg-6">
                    <FormGroup>
                      <Label for="childName">Name</Label>
                      <Input id="childName" name="childName" type="text" style={{ border: '1px solid darkgray' }} onChange={(e) => this.setState({ childrenName: e.target.value })} value={this.state.childrenName} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="childrenBirthdate">Birthday</Label>
                      <AppDatePicker id="childrenBirthdate" name="childrenBirthdate" onChange={(date: string) => this.setState({ childrenBirth: moment(date).format('YYYY-MM-DD') })} value={this.state.childrenBirth} />
                      {/* {this.state.childrenBirthdate !== '' ?
                        this.state.formErrors.childrenBirthdate.length > 0 && (
                          <span className="error-message">{this.state.formErrors.childrenBirthdate}</span>
                        ) : null} */}
                    </FormGroup>
                    <Button onClick={(e) => this.addItem(e)}>Add Child</Button>
                  </div>
                  <div className="col-lg-6">
                    {this.state.childrenBirthDates.map((child: any, index: number) => {
                      return (
                        <div key={index} className="child-card">
                          <img src={require('assets/remove-child.png')} alt="remove" className="remove-child-icon" onClick={() => this.removeItem(child.name)} />
                          <p className="child-name">Child Name: <span>{child.name}</span></p>
                          <p className="child-birth">Child Birthday: <span>{child.birthDate}</span></p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <Link to={this.state.cardReference ? '/card/' + this.state.cardReference : '/dashboard'} className="btn btn-info">Cancel</Link>{' '}
            <Button onClick={this.onSubmit.bind(this)} className="btn btn-success">Submit</Button>
          </Form>
        </div>
      </div>
    )
  }
}


export default withRouter(CardForm);
