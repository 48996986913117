import React from "react";
import { Button, Label, Input } from "reactstrap";
import moment from "moment";
import CardModel from "./models/CardModel";
import { Link } from "react-router-dom";
import _ from "lodash";
import AppCardPagination from "./AppCardPagination";

export default class CardList extends React.Component<any, any> {
  _termFilter: any;

  constructor(props: any) {
    super(props);
    this.state = {
      result: null,
      limit: 20,
      offset: 0,
      term: ""
    };

    this._termFilter = _.debounce((term: any) => {
      this.setState(
        {
          term
        },
        () => {
          this._loadTickets();
        }
      );
    }, 300);
  }

  componentDidMount() {
    this._loadTickets();
  }

  refresh() {
    this._loadTickets();
  }

  async _loadTickets() {
    let result: any = await new CardModel().getCards(
      this.state.term,
      this.state.limit,
      this.state.offset
    );

    this.setState({ result });
  }

  _goToPage(page: any) {
    const newOffset = (page - 1) * this.state.limit;
    this.setState(
      {
        offset: newOffset
      },
      () => {
        this._loadTickets();
      }
    );
  }

  _handleFilter(e: any) {
    this._termFilter(e.target.value);
  }

  async _deleteCard(card: any) {
    if (
      !window.confirm(
        "Are you sure you wish to delete card " + card.referenceNumber + "?"
      )
    ) {
      return;
    }

    // Delete card
    let response = await new CardModel().deleteCard(card);
    if (response) {
      alert("Card deleted!");
    } else {
      alert("Card not deleted!");
    }

    this.refresh();
  }

  render() {
    if (!this.state.result) {
      return null;
    }
    return (
      <div className="container" style={{ marginTop: 20 }}>
        <div className="box">
          <div className="header">
            <p>Cards</p>
          </div>
          <div className="content no-padding">
            <div className="row" style={{ padding: "10px 0" }}>
              <div className="col-sm-6 offset-sm-3">
                <Label>Filter by term</Label>
                <Input
                  type="text"
                  name="term"
                  placeholder={"Search..."}
                  onKeyUp={this._handleFilter.bind(this)}
                />
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Card Number</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Tickets issued</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.result.items.map((result: any) => {
                  return (
                    <tr key={result.id}>
                      <td>{result.id}</td>
                      <td>{result.referenceNumber}</td>
                      <td>{result.user && result.user.firstName}</td>
                      <td>{result.user && result.user.email}</td>
                      <td>{result.ticketsIssued || 0}</td>
                      <td>
                        {moment(result.created).format("D.M.YYYY. HH:mm:ss")}
                      </td>
                      <td>
                        <Link
                          to={"/card/" + result.referenceNumber}
                          className="btn btn-sm btn-primary"
                        >
                          Details
                        </Link>{" "}
                        <Button
                          onClick={() => this._deleteCard(result)}
                          color="danger"
                          size="sm"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <AppCardPagination
              goToPage={this._goToPage.bind(this)}
              pagination={this.state.result.pagination}
            />
          </div>
        </div>
      </div>
    );
  }
}
