import React, { Component } from 'react'
import { Route } from 'react-router-dom';
import LoginPage from '../../modules/auth/LoginPage';

export default class PublicLayout extends Component<any, any> {
  
  componentDidMount() {
    let token = localStorage.getItem('token');
    if(token) {
      // this.props.history.push('/dashboard')
    }
  }
  
  render() {
    return (
      <div className="public-layout">
          <Route exact path="/" component={LoginPage} />
      </div>
    )
  }
}
