import React from "react";
import { Link } from "react-router-dom";

export default class CardUserInfo extends React.Component<any, any> {
  render() {
    let card = this.props.card;
    let user = card.user;

    let fields: any = {
      "E-mail": user.email || "-",
      "First Name": user.firstName || "-",
      "Last Name": user.lastName || "-",
      Phone: user.phone || "-",
      Note: user.note || "-"
    };

    return (
      <div className="box">
        <div className="header">
          <p>User Info</p>
          <div className="button-holder">
            <Link
              to={"/card/" + card.referenceNumber + "/edit"}
              className="btn btn-sm btn-primary"
            >
              Edit
            </Link>
          </div>
        </div>
        <div className="content">
          <dl className="row">
            {Object.keys(fields).map(key => {
              return (
                <React.Fragment>
                  <dt
                    className="col-sm-5 text-left"
                    style={{ wordBreak: "break-all" }}
                  >
                    {key}
                  </dt>{" "}
                  <dd
                    className="col-sm-7 text-right"
                    style={{ wordBreak: "break-all" }}
                  >
                    {fields[key]}
                  </dd>
                </React.Fragment>
              );
            })}
          </dl>
        </div>
      </div>
    );
  }
}
