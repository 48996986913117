import React, { Component } from 'react'
import { Button, Form, } from 'reactstrap';

export default class AddedTickets extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tickets: []
    }
  }


  _updateAmount(ticket: any, amount: any) {
    if(this.props.onUpdateAmount) {
      this.props.onUpdateAmount(ticket, amount);
    }
  }

  _sendTickets(e: any) {
    e.preventDefault();
    if(this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  render() {
    let { tickets } = this.props;

    if (!tickets || !tickets.length) {
      return null;
    }
    // console.log('CARD TYPES', this.props.ticketTypes)
    return (
      <React.Fragment>
        <div>
          <p>Added tickets</p>
          <Form onSubmit={(e) => this._sendTickets(e)}>
            {
              this.props.tickets.map((ticket: any) => {
                return (
                  <div className="well added-ticket">
                    <div className="row">
                      <div className="col-sm-10">
                        <h3>{ticket.play.playName}</h3>
                        <p>Ticket type: {ticket.ticketType.name}</p>
                        <p>Amount: {ticket.amount}</p>
                      </div>
                      <div className="col-sm-2">
                        <a href="javascript:void(0)" onClick={() => this._updateAmount(ticket, 0)}>Remove</a>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            <Button type="submit">Submit</Button>
          </Form>
        </div>
      </React.Fragment>
    )
  }
}
