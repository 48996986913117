import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { GET_TOKEN_ON_LOGIN, GET_USER_DATA } from '../../logic/auth/AuthActions';
import LoginModel from './models/LoginModel';

interface LoginState {
  email: string,
  password: string,
  formErrors: any
}
const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

class LoginPage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      formErrors: {
        email: '',
        password: '',
      }
    }
  }

  async onSubmit(e: any) {
    e.preventDefault();

    // this.setState({ buttonText: 'Loading...' })
    if (this.state.email !== '' && this.state.password !== '') {
      let response: any = await (new LoginModel(this.state.email, this.state.password)).logIn(this.state.email, this.state.password);

      if (response.success) {
        localStorage.setItem('token', response.data.token);
        this.props.updateToken(response.data.token)
        this.props.getProfileData(response.data.account.profile);
        this.props.history.push('/dashboard');
      } else {
        alert('Username/password invalid');
      }
    }
  }

  checkForValues() {
    let s = this.state;
    if (s.email && s.password !== '') {
      return true
    } else {
      return false
    }
  }

  handleChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "password":
        formErrors.password =
          value.length < 6 ? "minimum 6 characaters required" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  render() {
    return (
      <div className="login-page">
        <div className="login-container">
          <h1 >Login</h1>
          <Form onSubmit={this.onSubmit} >
            <FormGroup>
              <Label for="exampleEmail">Email</Label>
              <Input
                id="email"
                name="email"
                style={this.state.email !== '' ? this.state.formErrors.email.length > 0 ? { border: '1px solid red' } : { border: '1px solid gray' } : { border: '1px solid gray' }}
                onChange={this.handleChange}
                value={this.state.email}
              />
              {this.state.email !== '' ?
                this.state.formErrors.email.length > 0 && (
                  <span className="error-message">{this.state.formErrors.email}</span>
                ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input
                id="password"
                name="password"
                type="password"
                onChange={this.handleChange}
                value={this.state.password}
                style={this.state.password !== '' ? this.state.formErrors.password.length > 0 ? { border: '1px solid red' } : { border: '1px solid gray' } : { border: '1px solid gray' }}
              />
            </FormGroup>
            {/* <Link to="/dashboard"> </Link> */}
            <Button type="submit" onClick={this.onSubmit.bind(this)}>Submit</Button>
          </Form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  state
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateToken: (token: any) => {
      dispatch({ type: GET_TOKEN_ON_LOGIN, payload: token })
    },
    getProfileData: (profile: any) => {
      dispatch({ type: GET_USER_DATA, payload: profile })
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);