import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

export default class CardChildrenInfo extends React.Component<any, any> {
  render() {
    let card = this.props.card;
    let user = card.user;

    if (!user || !user.childrenBirthDates) return null;

    const childrenBirthDates = JSON.parse(user.childrenBirthDates);

    return (
      <div className="box">
        <div className="header">
          <p>Birthdays</p>
          <div className="button-holder">
            <Link
              to={"/card/" + card.referenceNumber + "/edit"}
              className="btn btn-sm btn-primary"
            >
              Edit
            </Link>
          </div>
        </div>
        <div className="content no-padding">
          <table className="table">
            <thead>
              <tr>
                <th>Child Name</th>
                <th>Birthday</th>
              </tr>
            </thead>
            <tbody>
              {childrenBirthDates.map((birthday: any) => {
                return (
                  <tr>
                    <td>{birthday.name || " - "}</td>
                    <td>{moment(birthday.birthDate).format("DD.MM.YYYY.")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
