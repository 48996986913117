import Api from 'logic/api/Api';

export default class CardModel {

  async getCards(term: string|null, limit: any = 20, offset: any = 0) {
    let response: any = await Api.call('card/list',
      {
        term,
        limit,
        offset
      }
    );

    return response.data;
  }

  async getTickets(card: any, limit: any = 20, offset: any = 0) {
    let response: any = await Api.call('card/tickets',
      {
        cardId: card.id,
        limit,
        offset
      }
    );

    return response.data;
  }


  async deleteCard(card: any) {
    let response: any = await Api.call('card/delete',
      {
        cardId: card.id,
      }
    );

    return response.success;
  }
  
}
