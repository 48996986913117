import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PublicLayout from "./components/layout/PublicLayout";
import DefaultLayout from "./components/layout/DefaultLayout";
import { GET_TOKEN_ON_LOGIN } from "./logic/auth/AuthActions";
import { connect } from "react-redux";
import TicketReservation from "./modules/ticket-reservation/TicketReservation";

const App: React.FC = (props: any) => {
  const [authLoaded, setAuthLoaded] = useState(false);

  useEffect(() => {
    let authToken = localStorage.getItem("token");
    if (authToken) {
      props.updateToken(authToken);
    }
    setAuthLoaded(true);
  });

  if (!authLoaded) {
    return null;
  }

  return (
    <div className="App">
      <Router>
        <Route path="/" component={PublicLayout} />
        <Route path="/card/:referenceNumber" component={DefaultLayout} />
        <Route path="/cards" component={DefaultLayout} />
        <Route path="/dashboard" component={DefaultLayout} />
        <Route path="/new-card" component={DefaultLayout} />
        <Route path="/email/confirm/:hash" component={TicketReservation} />
      </Router>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateToken: (token: any) => {
      dispatch({ type: GET_TOKEN_ON_LOGIN, payload: token });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
