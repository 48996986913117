import React, { Component, useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

export default class AddTickets extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tickets: [],
      amount: 0
    };
  }

  removeOneByOne(item: any) {
    const amount = this._getAmountForTicketType(item);
    this.props.onTicketAdd(item, amount - 1);
  }

  addOneByOne(item: any) {
    const amount = this._getAmountForTicketType(item);
    this.props.onTicketAdd(item, amount + 1);
  }
  addManual(item: any, count: any) {
    // const amount = this._getAmountForTicketType(item);
    this.props.onTicketAdd(item, count);
  }

  _getAmountForTicketType(ticketType: any) {
    const playId = this.props.playId;

    const ticketsForPlay = this.props.tickets.filter(
      (x: { playId: any }) => x.playId === playId
    );
    // console.log(ticketsForPlay)

    if (ticketsForPlay.length === 0) return 0;

    const ticketsForTicketType = ticketsForPlay.find(
      (x: { ticketTypeId: any }) => x.ticketTypeId === ticketType.id
    );
    if (!ticketsForTicketType) return 0;

    return ticketsForTicketType.amount;
  }

  render() {
    let { ticketTypes } = this.props;

    if (!ticketTypes || !ticketTypes.length) {
      return null;
    }
    // console.log('CARD TYPES', this.props.ticketTypes)
    return (
      <React.Fragment>
        <div style={{ marginTop: 20 }}>
          <p>Select ticket type</p>
          <Form>
            {this.props.ticketTypes.map((type: any) => {
              let amount = this._getAmountForTicketType(type);
              return (
                <TicketType
                  amount={amount}
                  removeOneByOne={this.removeOneByOne.bind(this)}
                  addOneByOne={this.addOneByOne.bind(this)}
                  addManual={this.addManual.bind(this)}
                  type={type}
                />
                // <FormGroup key={type.id}>
                //   <Label for={type.slug}>{type.name}</Label>
                //   <div className="count-input-wrapper">
                //     <Button onClick={() => this.removeOneByOne(type)}>-</Button>
                //     <Input type="number" name={type.slug} id={type.slug} value={this.state.amount !== 0 ? this.state.amount : amount} onChange={(e) => this.setState({amount: e.target.value})}/>
                //     <Button onClick={() => this.addOneByOne(type)}>+</Button>
                //   </div>
                //   <Button onClick={() => this.addManual(type)}>Add Manually</Button>
                // </FormGroup>
              );
            })}
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

const TicketType: React.FC<any> = (props: any) => {
  const [amount, setAmount] = useState(props.amount);
  let [prevAmount, setPrevAmount] = useState(props.amount);

  if (props.amount !== prevAmount) {
    setAmount(props.amount);
    setPrevAmount(props.amount);
  }

  const type = props.type;

  return (
    <FormGroup key={type.id}>
      <Label for={type.slug}>{type.name}</Label>
      <div className="count-input-wrapper">
        <Button onClick={() => props.removeOneByOne(type)}>-</Button>
        <Input
          type="number"
          name={type.slug}
          id={type.slug}
          value={amount}
          onFocus={(e: any) => e.target.select()}
          onKeyUp={(e: { keyCode: number }) =>
            e.keyCode === 13 && props.addManual(type, amount)
          }
          onChange={(e: { target: { value: any } }) =>
            setAmount(parseInt(e.target.value))
          }
        />
        <Button onClick={() => props.addOneByOne(type)}>+</Button>
      </div>
    </FormGroup>
  );
};
