import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../../modules/dashboard/Dashboard";
import IssueNewCard from "../../modules/new-card/IssueNewCard";
import NavBar from "../navigation/NavBar";
import CardDetails from "modules/card/CardDetails";
import CardEdit from "modules/card/CardEdit";
import CardList from "modules/card/CardList";

export default class DefaultLayout extends Component {
  render() {
    return (
      <div className="default-layout">
        <NavBar {...this.props} />
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route path="/card/:referenceNumber/edit" component={CardEdit} />
          <Route path="/card/:referenceNumber" component={CardDetails} />
          <Route path="/cards" component={CardList} />
          <Route path="/new-card" component={IssueNewCard} />
        </Switch>
      </div>
    );
  }
}
