import Api from "logic/api/Api";
export default class DashboardModel {
  async cardScan(referenceNumber: any) {
    let response = await Api.call("card/scan", {
      referenceNumber
    });
    return response;
  }

  async getListOfPlays(limit: any, offset: any) {
    let response = await Api.call("play/list", {
      limit,
      offset
    });
    return response;
  }

  async getTicketType(playId: any) {
    let response = await Api.call("play/ticket-types", {
      playId
    });
    return response;
  }

  async getLastScan() {
    let response: any = await Api.call("pos/getLastScan");
    return response.data;
  }

  async pool(id: any) {
    let response = await Api.call("pos/pool", {
      hash: "global",
      lastScanId: id
    });

    return response;
  }
}
