import React, { Component } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";

export default class ScanCard extends Component<any, any> {
  state = {
    showConnectWidget: false,
    cardReferenceContent: ""
  };

  _onSubmit(e: any) {
    e.preventDefault();
    this.props.handlerScanCard(e);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={
            "col-lg-" +
            (this.state.showConnectWidget ? "8" : "12") +
            " dashboard-column scan-card"
          }
        >
          <div className="box">
            <div className="header">
              <p>Scan card</p>
            </div>
            <div className="content">
              <p>
                Please scan the card with the app <span>or</span>
              </p>
              <p>Type in the card number and click submit</p>
              <Form onSubmit={e => this._onSubmit(e)}>
                <FormGroup>
                  <div
                    className="card-number-input-wrapper"
                    style={{ margin: "auto", maxWidth: "400px" }}
                  >
                    <Input
                      type="text"
                      name="cardReference"
                      id="cardReference"
                      placeholder="Card number"
                      onChange={this.props.onChange}
                    />{" "}
                    <Button
                      color="primary"
                      onClick={this.props.handlerScanCard}
                    >
                      Submit
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        {this.state.showConnectWidget ? (
          <div className="col-lg-4 dashboard-column qr-code">
            <div className="header">
              <p>Connect POS App</p>
            </div>
            <div className="content">
              <p>Please scan the code to connect the app</p>
              <img src={require("../../../assets/qr.png")} alt="qr code" />
              <p>Connected device:</p>
              <p className="green" style={{ marginBottom: 0 }}>
                Android 6rpf65324f
              </p>
              <p className="red" style={{ fontSize: 12 }}>
                (disconnect)
              </p>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
