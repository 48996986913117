import React, { Component } from "react";
import AddTickets from "./AddTickets";
import Dropdown from "react-dropdown";
import AddedTickets from "./AddedTickets";
import Api from "logic/api/Api";
import DashboardModel from "modules/dashboard/models/DashboardModel";
import { withCookies } from "react-cookie";

interface NewListItem {
  value: string;
  label: string;
}

export class TicketsWidget extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tickets: [],
      plays: [],
      playsList: [],
      selectedPlay: "",
      cardTypes: []
    };
  }

  _onTicketAdd(play: any, ticketType: any, amount: number) {
    const tickets = [...this.state.tickets];

    const playId = play.id;
    const ticketTypeId = ticketType.id;
    const newTickets = tickets.filter(
      x => !(x.playId === playId && x.ticketType.id === ticketTypeId)
    );

    if (amount > 0) {
      newTickets.push({
        play,
        playId,
        ticketTypeId,
        ticketType,
        amount
      });
    }

    this.setState({
      tickets: newTickets
    });
  }

  _addedTickets(ticketType: any, amount: number) {
    const play = this.state.plays.find(
      (x: { id: any }) => x.id === this.state.selectedPlay.value
    );

    this._onTicketAdd(play, ticketType, amount);
  }

  componentDidMount() {
    this.getPlaysList();
  }

  async getPlaysList() {
    let limit = 0;
    let offset = 0;
    await new DashboardModel()
      .getListOfPlays(limit, offset)
      .then((response: any) => {
        this.setState({ plays: response.data.items });
      });
    this.listPlays();
  }

  listPlays() {
    console.log("cookeies", this.props.cookies);
    const lastSelectedPlayId = this.props.cookies.get("lastSelectedPlayId");

    // const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    // console.log('cookies', cookies);
    let plays = this.state.plays;
    let newList: NewListItem[] = [];
    if (plays !== []) {
      let stateUpdate: any = {};

      plays.map((play: any) => {
        let playOption = {
          value: play.id,
          label: play.playName
        };
        newList.push(playOption);

        if (lastSelectedPlayId === play.id) {
          stateUpdate.selectedPlay = playOption;
        }
      });

      stateUpdate.playsList = newList;

      this.setState(stateUpdate, () => {
        if (stateUpdate.selectedPlay) {
          this._onSelect(stateUpdate.selectedPlay);
        }
      });
    }
  }

  _onSelect(option: any) {
    this.setState({ selectedPlay: option }, () => {
      this._setCookie(option.value);
      this.getTypes();
    });
  }

  _setCookie(playId: any) {
    let aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    this.props.cookies.set("lastSelectedPlayId", playId, {
      path: "/",
      expires: aYearFromNow
    });
  }

  async getTypes() {
    let newList: any[] = [];
    await new DashboardModel()
      .getTicketType(this.state.selectedPlay.value)
      .then((response: any) => {
        response.data.map((cardType: any) => {
          newList.push({
            id: cardType.id,
            name: cardType.name,
            slug: cardType.slug
          });
          this.setState({ cardTypes: newList });
        });
      });
  }

  async _submitTickets() {
    const ticketsForSend = this.state.tickets.map(
      (ticket: { playId: any; ticketTypeId: any; amount: any }) => ({
        playId: ticket.playId,
        ticketTypeId: ticket.ticketTypeId,
        amount: ticket.amount
      })
    );

    this.setState({
      loading: true
    });

    const tickets = ticketsForSend;

    const result: any = await Api.call("card/tickets/add/multiple", {
      cardId: this.props.card.id,
      tickets
    });

    if (result.success) {
      this.setState(
        {
          loading: false,
          tickets: [],
          selectedPlay: "",
          cardTypes: []
        },
        () => {
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
          alert("You have successfully added a new tickets.");
        }
      );
    }
  }

  render() {
    return (
      <div className="box">
        <div className="header">
          <p>Add Tickets</p>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-sm-4">
              <p>Add new ticket</p>
              <Dropdown
                options={this.state.playsList}
                onChange={option => this._onSelect(option)}
                value={this.state.selectedPlay}
                placeholder="Select a play"
              />

              <AddTickets
                ticketTypes={this.state.cardTypes}
                playId={this.state.selectedPlay.value}
                plays={this.state.plays}
                tickets={this.state.tickets}
                onTicketAdd={(ticketType: any, amount: number) =>
                  this._addedTickets(ticketType, amount)
                }
              />
              {/* <Button>Add</Button> */}
            </div>
            <div className="col-sm-8">
              <AddedTickets
                tickets={this.state.tickets}
                onUpdateAmount={(ticket: any, amount: any) =>
                  this._onTicketAdd(ticket.play, ticket.ticketType, amount)
                }
                onSubmit={() => this._submitTickets()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(TicketsWidget);
