import { createStore, applyMiddleware } from 'redux';
import reducers from './AppReducers';
import logger from 'redux-logger';

// const createStoreWithMiddleware = applyMiddleware()(createStore);
const store = createStore(reducers,
    applyMiddleware(logger)
);

export default store;
