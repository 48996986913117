import Api from '../logic/api/Api';


export const logOut = () => {
 Api.call(
    'auth/logout',
    {}
  );
  localStorage.removeItem('token');
  setTimeout(() => {
     window.location.replace('/')
  }, 1000);
}
