import { combineReducers } from 'redux';
import { GET_TOKEN_ON_LOGIN } from './AuthActions';
import { GET_USER_DATA } from './AuthActions';

const initialState = {
  token: null,
  profile: null
}
function token(state = initialState, action: any) {
  switch (action.type) {
    case GET_TOKEN_ON_LOGIN:
      return action.payload;
    default:
      return state
  }
}
function profile(state = initialState.profile, action: any) {
  switch (action.type) {
    case GET_USER_DATA:
      return action.payload;
    default:
      return state
  }
}

export default combineReducers({
  token,
  profile
});