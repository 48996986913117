import React from 'react'
import { CardForm } from 'modules/card/components/CardForm';

export default class IssueNewCard extends React.Component<any, any> {

  render() {
    // if (this.props.location.state) {
    //   alert(this.props.location.state)
    // }
    return (
      <div>
        <h1>Issue New Card</h1>
        <div className="content container" style={{ maxWidth: 1140 }}>
          <CardForm history={this.props.history} {...this.props}/>
        </div>
      </div>
    );
  }
}
