import React from 'react';
import Api from 'logic/api/Api';

export default class NewCardModel extends React.Component {

  async saveCard(id: string|null, referenceNumber: string, email:string, firstName:string, lastName:string, address:string, phone:string, city:string, postCode:string, birthDate:string, note: string, childrenBirthDates:string ) {
    let response = await Api.call(
      'customer/add',
      {
        id,
        referenceNumber,
        email,
        firstName,
        lastName,
        address,
        phone,
        city,
        postCode,
        birthDate,
        note,
        childrenBirthDates,
      }
    );

    return response;
  }
}
