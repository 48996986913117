import {Component} from 'react';
import Api from 'logic/api/Api';

export default class LoginModel extends Component<{props: any}> {

  getLogoutUrl() {
    return '/auth/logout'
  }
  async logIn(email:string, password:string) {
    let response = await Api.call(
      'auth/login',
      {
        email,
        password,
      }
    );

    return response;
  }
  async logOut() {
    let response = await Api.call(
      'auth/logout',
      {}
    );
    return response;
  }
}
