import React from "react";
import DashboardModel from "modules/dashboard/models/DashboardModel";
import CardForm from "./components/CardForm";

export default class CardEdit extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      card: null
    };
  }

  componentDidMount() {
    let referenceNumber = this.props.match.params.referenceNumber;

    if (!referenceNumber) {
      this.props.history.push("/dashboard");
      return;
    }

    this.setState(
      {
        cardReference: referenceNumber
      },
      () => {
        this.scanCard(this.state.cardReference);
      }
    );
  }

  _addedTickets(ticketType: any, amount: number) {
    const play = this.state.plays.find(
      (x: { id: any }) => x.id === this.state.selectedPlay
    );

    this.props.onTicketAdd(play, ticketType, amount);
  }

  handlerScanCard = (e: any) => {
    e.preventDefault(e);
    this.scanCard(this.state.cardReference);
  };

  _refreshCard() {
    this.scanCard(this.state.cardReference);
  }

  async scanCard(cardReference: string) {
    if (cardReference !== "") {
      let response: any = await new DashboardModel().cardScan(cardReference);

      if (!response.success) {
        alert("Card not found!");
        this.props.history.push("/dashboard");
      } else {
        let date1 = new Date(response.data.created);
        let date2 = new Date();
        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.setState({
          card: response.data,
          cardId: response.data.id,
          discount: response.data.discount,
          cardCreated: response.data.created,
          timesUsed: response.data.timesUsed,
          cardAge: diffDays
        });
      }
    }
  }

  render() {
    if (!this.state.card) {
      return <div>Loading...</div>;
    }
    return (
      <div className="dashboard-page">
        <h1>Edit Card: {this.state.card.referenceNumber}</h1>
        <div className="container">
          <CardForm card={this.state.card} />
        </div>
      </div>
    );
  }
}
