import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { Input } from 'reactstrap';

export default class AppDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  // onChange = (date: any) => this.setState({ date })
  handleInputChange(e) {
    if(this.props.handler) {
      this.props.handler.handleInputChange(e);
    }
  }
  // handleDateChange(date) {
  //   this.props.handler.handleInputChange(this.props.name, moment(date).format('l'));
  // }
  _openDatePicker() {
    if (this._datepicker) {
      setTimeout(() => {
        this._datepicker.openCalendar();
      }, 100)
    }
  }
  render() {
    return (
      <div>
        <Input
          ref="actualInput"
          autoComplete={this.props.autoComplete}
          label={this.props.label}
          value={this.props.value}
          className={this.props.className}
          type={this.props.type}
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={this.handleInputChange.bind(this)}
          onFocus={() => this._openDatePicker()} />
        <DatePicker
          ref={datepicker => this._datepicker = datepicker}
          // onChange={this.onChange}
          // onChange={date => this.handleDateChange(date)}
          onChange={this.props.onChange}
          // onChange={date => this.handleDateChange(date)}
          {...this.props.datepickerProps}
        />
      </div>
    );
  }
}
