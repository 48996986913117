import { combineReducers } from 'redux';
import AuthReducers from '../auth/AuthReducers';

// function ready(state = false, action: any = null) {
//   switch(action.type) {
//     case 'GET_TOKEN_ON_LOGIN':
//       return true;
//   }
//   return state;
// }

export default combineReducers({
  // ready,
  auth: AuthReducers
});