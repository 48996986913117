export const GET_TOKEN_ON_LOGIN = 'GET_TOKEN_ON_LOGIN';
export const GET_USER_DATA = 'GET_USER_DATA';

export const loginTriggerAction = (token: any, account:any) => ({
  type: GET_TOKEN_ON_LOGIN,
  payload: {
    token
  }
});
export const getUserData = (profile: any, account:any) => ({
  type: GET_TOKEN_ON_LOGIN,
  payload: {
    profile
  }
});
