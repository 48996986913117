import Api from "logic/api/Api";

export default class TicketReservationModel {
  async sendToken(hash: any) {
    let response: any = await Api.call("customer/email/confirm", {
      hash: hash
    });

    return response.success;
  }
}
