import React from "react";
import CardModel from "../models/CardModel";
import moment from "moment";
import AppCardPagination from "../AppCardPagination";

export default class CardTickets extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      result: null,
      limit: 10,
      offset: 0
    };
  }
  componentDidMount() {
    this._loadTickets();
  }

  async _loadTickets() {
    let result: any = await new CardModel().getTickets(
      this.props.card,
      this.state.limit,
      this.state.offset
    );

    this.setState({ result });
  }

  _goToPage(page: any) {
    const newOffset = (page - 1) * this.state.limit;
    this.setState(
      {
        offset: newOffset
      },
      () => {
        this._loadTickets();
      }
    );
  }

  render() {
    if (!this.state.result) {
      return null;
    }

    return (
      <div className="box">
        <div className="header">
          <p>Card Tickets</p>
        </div>
        <div className="content no-padding">
          <table className="table">
            <thead>
              <tr>
                <td>ID</td>
                <td>Play</td>
                <td>Amount</td>
                <td>Date</td>
              </tr>
            </thead>
            <tbody>
              {this.state.result.items.map((result: any) => {
                return (
                  <tr key={result.id}>
                    <td>{result.id}</td>
                    <td>{result.play.playName}</td>
                    <td>{result.amount}</td>
                    <td>
                      {moment(result.created).format("D.M.YYYY. HH:mm:ss")}
                    </td>
                  </tr>
                );
              })}
              {this.state.result.items.length === 0 ? (
                <tr>
                  <td colSpan={4}>
                    <em>no tickets</em>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <AppCardPagination
            goToPage={this._goToPage.bind(this)}
            pagination={this.state.result.pagination}
          />
        </div>
      </div>
    );
  }
}
