import React, { Component } from "react";
import TicketReservationModel from "./models/TicketReservationModel";
import "../../styles/style.scss";

class TicketReservation extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      status: "loading"
    };
  }

  componentDidMount() {
    this.validateHash(this.props.match.params.hash);
  }

  async validateHash(hash: string) {
    let response: any = await new TicketReservationModel().sendToken(hash);

    if (!response) {
      this.setState({
        status: "failed"
      });
    } else {
      this.setState({
        status: "success"
      });
    }
  }

  render() {
    if (this.state.status === "failed") {
      return (
        <div className="email-verification">
          <div>
            <p>Верификација је неуспешна. Молимо покушајте поново.</p>
            <img
              src={require("../../assets/puz-mali.png")}
              alt="Animated small snail."
            />
          </div>
        </div>
      );
    }

    if (this.state.status === "success") {
      return (
        <div className="email-verification">
          <div>
            <p>
              Верификација је успешна. Можете преузети вашу картицу у позоришту.
            </p>
            <img
              src={require("../../assets/puz-mali.png")}
              alt="Animated small snail."
            />
          </div>
        </div>
      );
    }

    return (
      <div className="email-verification">
        <div>
          <p>Молимо сачекајте. Верификација у току...</p>
          <img
            src={require("../../assets/puz-mali.png")}
            alt="Animated small snail."
          />
        </div>
      </div>
    );
  }
}

export default TicketReservation;
