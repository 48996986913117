import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import CardUserInfo from "modules/card/components/CardUserInfo";
import CardTickets from "modules/card/components/CardTickets";
import TicketsWidget from "modules/card/components/TicketsWidget";
import DashboardModel from "modules/dashboard/models/DashboardModel";
import CardChildrenInfo from "./components/CardChildernInfo";

export default class CardDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      card: null,
      cardNotFound: false
    };
  }

  componentDidMount() {
    let referenceNumber = this.props.match.params.referenceNumber;

    if (!referenceNumber) {
      this.props.history.push("/dashboard");
      return;
    }

    this.setState(
      {
        cardReference: referenceNumber
      },
      () => {
        this.scanCard(this.state.cardReference);
      }
    );
  }

  _addedTickets(ticketType: any, amount: number) {
    const play = this.state.plays.find(
      (x: { id: any }) => x.id === this.state.selectedPlay
    );

    this.props.onTicketAdd(play, ticketType, amount);
  }

  handlerScanCard = (e: any) => {
    e.preventDefault(e);
    this.scanCard(this.state.cardReference);
  };

  _refreshCard() {
    this.scanCard(this.state.cardReference);
  }

  async scanCard(cardReference: string) {
    if (cardReference !== "") {
      let response: any = await new DashboardModel().cardScan(cardReference);
      if (!response.success) {
        // alert('Card not found!');
        this.setState({ card: true, cardNotFound: true });
        // this.props.history.push('/dashboard');
      } else {
        let date1 = new Date(response.data.created);
        let date2 = new Date();
        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.setState({
          card: response.data,
          cardId: response.data.id,
          discount: response.data.discount,
          cardCreated: response.data.created,
          timesUsed: response.data.timesUsed,
          cardAge: diffDays
        });
      }
    }
  }

  handleIssueNewCard() {
    this.props.history.push("/new-card", this.state.cardReference);
  }

  render() {
    if (!this.state.card) {
      return <div>Loading...</div>;
    } else if (this.state.cardNotFound) {
      return (
        <div className="card-not-found">
          <h3>Card with number {this.state.cardReference} not found</h3>
          <p>Would you like to add this card?</p>
          <div className="buttons-wrapper">
            <Button onClick={() => this.handleIssueNewCard()}>Yes</Button>
            <Link to="/dashboard">
              <Button>No</Button>
            </Link>
          </div>
        </div>
      );
    }
    return (
      <div className="dashboard-page">
        <h1>Card: {this.state.card.referenceNumber}</h1>
        <div className="col-lg-12" style={{ marginBottom: 20 }}>
          <Button onClick={() => this.props.history.push("/dashboard")}>
            Scan another card
          </Button>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 dashboard-column card-details">
              <div className="box">
                <div className="header">
                  <p>Card details</p>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-sm-4">
                      <img
                        src={require("../../assets/card.jpg")}
                        className="card-image"
                        alt="card"
                      />
                    </div>
                    <div className="col-sm-4 d-flex align-items-center">
                      <ul>
                        <li>
                          <p style={{ textAlign: "left" }}>
                            Ticket No: {this.state.card.referenceNumber}
                          </p>
                        </li>
                        <li>
                          <p style={{ textAlign: "left" }}>
                            Ordered:{" "}
                            {moment(this.state.cardCreated).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        </li>
                        <li>
                          <p style={{ textAlign: "left" }}>
                            Tickets issued: {this.state.card.ticketsIssued || 0}
                          </p>
                        </li>
                        <li>
                          <p style={{ textAlign: "left" }}>
                            Card age: {this.state.cardAge} days
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-4 d-flex align-items-center justify-content-center">
                      <div className="current-discount">
                        <p>
                          Current discount <span>{this.state.discount}%</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 dashboard-column add-ticket">
              <TicketsWidget
                card={this.state.card}
                onSubmit={() => this._refreshCard()}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <CardUserInfo card={this.state.card} />
              <CardChildrenInfo card={this.state.card} />
            </div>
            <div className="col-sm-8">
              <CardTickets card={this.state.card} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
