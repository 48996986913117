import React, { Component } from "react";
import { Button } from "reactstrap";
import DashboardModel from "./models/DashboardModel";
import { GET_TOKEN_ON_LOGIN } from "../../logic/auth/AuthActions";
import { connect } from "react-redux";
import "react-dropdown/style.css";
import ScanCard from "./components/ScanCard";

class Dashboard extends Component<any, any> {
  _timeout: any;

  constructor(props: any) {
    super(props);
    this.state = {
      showConnectWidget: false,
      cardId: "",
      adults: 0,
      kids: 0,
      card: false,
      cardScan: true,
      cardReference: "",
      discount: "",
      timesUsed: "",
      cardCreated: "",
      cardAge: "",
      limit: 0,
      offset: 0,
      // plays: [],
      selectedPlay: "",
      gotType: false,
      tickets: []
    };
    this.handlerScanCard = this.handlerScanCard.bind(this);
  }

  componentDidMount() {
    this._startPooling();
  }

  componentWillUnmount() {
    this._endPooling();
  }

  async _startPooling() {
    let lastScan = await new DashboardModel().getLastScan();
    this._pool(lastScan.id);
  }

  _endPooling() {
    try {
      if (this._timeout) {
        clearTimeout(this._timeout);
      }
    } catch (e) {}
  }

  async _pool(id: any) {
    let result: any = await new DashboardModel().pool(id);

    if (result.success && result.data && result.data.referenceNumber) {
      this.props.history.push("/card/" + result.data.referenceNumber);
    } else {
      this._timeout = setTimeout(() => {
        this._pool(id);
      }, 500);
    }
  }

  _addedTickets(ticketType: any, amount: number) {
    const play = this.state.plays.find(
      (x: { id: any }) => x.id === this.state.selectedPlay
    );

    this.props.onTicketAdd(play, ticketType, amount);
  }

  handlerScanCard = (e: any) => {
    e.preventDefault(e);
    if (
      !this.state.cardReference ||
      this.state.cardReference.trim().length === 0
    ) {
      return;
    }

    this.props.history.push("/card/" + this.state.cardReference);
    // this.scanCard(this.state.cardReference);
  };

  _refreshCard() {
    this.scanCard(this.state.cardReference);
  }

  async scanCard(cardReference: string) {
    if (cardReference !== "") {
      let response: any = await new DashboardModel().cardScan(cardReference);

      if (!response.success) {
        // alert('Card not found!');
        this.setState({ cardNotFound: true });
      } else {
        let date1 = new Date(response.data.created);
        let date2 = new Date();
        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.setState({
          card: response.data,
          cardId: response.data.id,
          discount: response.data.discount,
          cardCreated: response.data.created,
          timesUsed: response.data.timesUsed,
          cardAge: diffDays
        });
      }
    }
  }

  render() {
    return (
      <div className="dashboard-page">
        <h1>Dashboard</h1>
        <div className="container">
          <div className="row">
            {this.state.card ? null : (
              <ScanCard
                onChange={(e: any) =>
                  this.setState({ cardReference: e.target.value })
                }
                handlerScanCard={this.handlerScanCard}
              />
            )}
            <div className="col-lg-12" style={{ marginBottom: 20 }}>
              {this.state.card ? (
                <Button onClick={() => this.setState({ card: false })}>
                  Scan another card
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateToken: (token: any) => {
      dispatch({ type: GET_TOKEN_ON_LOGIN, payload: token });
    }
  };
};

export default connect(mapDispatchToProps)(Dashboard);
