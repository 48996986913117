import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../services/AuthService";

interface NavBarState {
  isOpen: boolean;
}

class NavBar extends React.Component<any, NavBarState> {
  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  async logOutUser(e: any) {
    e.preventDefault();
    logOut();
  }

  render() {
    return (
      <div>
        <Navbar color="dark" dark expand="md">
          <div className="container">
            <NavbarBrand href="/dashboard">
              <img
                src={require("../../assets/caca logo final-02.svg")}
                className="navbar-logo"
                alt="logo"
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link to="/dashboard" className="nav-link">
                    Dashboard
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/new-card" className="nav-link">
                    Issue new card
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/cards" className="nav-link">
                    Cards
                  </Link>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="javascript:void(0)"
                    onClick={e => this.logOutUser(e)}
                  >
                    Logout
                  </NavLink>
                </NavItem>
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Options
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      Option 1
                    </DropdownItem>
                    <DropdownItem>
                      Option 2
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      Reset
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  state
});
export default connect(mapStateToProps)(NavBar);
